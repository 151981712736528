import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";

import { cn } from "@/helpers/className";

interface AlertProps {
  className?: string;
  message?: string;
  title?: string;
  type: "positive" | "negative" | "warning" | "info";
}

export const Alert = ({ type, title, message, className }: AlertProps) => {
  const typeMap = {
    positive: {
      color: "green",
      icon: CheckCircleIcon,
    },
    negative: {
      color: "red",
      icon: XCircleIcon,
    },
    warning: {
      color: "yellow",
      icon: ExclamationTriangleIcon,
    },
    info: {
      color: "blue",
      icon: InformationCircleIcon,
    },
  };

  const color = typeMap[type]?.color ?? "blue";
  const Icon = typeMap[type]?.icon ?? InformationCircleIcon;

  return (
    <div className={cn(`rounded-xl bg-${color}-50 p-4 shadow-md`, className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon className={`h-5 w-5 text-${color}-400`} aria-hidden="true" />
        </div>
        <div className="ml-3">
          {title && (
            <div className={`text-sm font-medium text-${color}-800`}>
              {title}
            </div>
          )}

          {message && (
            <div className={cn(`text-sm text-${color}-700`, title && "mt-2")}>
              <div dangerouslySetInnerHTML={{ __html: message }}></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
