import React from "react";

import { cn } from "@/lib/helpers/className";

interface SpriteProps {
  className?: string;
}

const Sprite = ({ className }: SpriteProps) => {
  return (
    <svg
      className={cn(
        "absolute opacity-30 transition-all duration-500",
        className,
      )}
      viewBox="0 0 285 285"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M141.872 284.315C105.002 284.315 68.8419 269.895 41.6919 242.745C-2.46812 198.605 -12.6281 131.835 16.4019 76.6049C23.0219 64.0049 31.5419 52.4449 41.6919 42.2949C45.3419 38.6449 51.2419 38.6449 54.9019 42.2949C58.5419 45.9549 58.5419 51.8549 54.9019 55.5049C46.0719 64.3249 38.6919 74.3549 32.9419 85.2949C7.74188 133.235 16.5719 191.205 54.9019 229.535C89.9419 264.595 142.272 275.185 188.222 256.575C193.012 254.635 198.442 256.945 200.392 261.725C202.322 266.505 200.012 271.955 195.232 273.885C177.932 280.915 159.812 284.325 141.882 284.325L141.872 284.315Z"
        fill="#FFDC00"
      />
      <path
        d="M16.552 109.215C15.562 109.215 14.532 109.055 13.552 108.705C8.66198 107.055 6.03198 101.745 7.70198 96.865C14.712 76.175 26.152 57.825 41.692 42.285C91.832 -7.86503 171.972 -13.125 228.122 29.995C232.202 33.145 232.972 39.005 229.832 43.095C226.682 47.195 220.812 47.955 216.742 44.815C167.992 7.37497 98.432 11.965 54.902 55.505C41.402 68.995 31.482 84.925 25.382 102.865C24.062 106.765 20.422 109.215 16.552 109.215Z"
        fill="#F99800"
      />
      <path
        d="M148.952 246.805C124.252 248.465 99.6419 241.295 79.6919 226.505C33.3819 192.215 23.6119 126.615 57.9219 80.3051C60.3419 77.0551 62.9519 74.0252 65.6919 71.1052C66.5519 70.1852 67.4619 69.2952 68.3719 68.3952C89.6719 47.1752 118.972 36.0352 149.642 38.2852C154.722 38.5252 159.262 34.7852 159.642 29.6452C160.012 24.5052 156.142 20.0252 151.012 19.6452C117.092 17.1752 84.5919 28.7151 59.9119 50.8351C59.6019 51.0551 59.2419 51.1552 58.9519 51.4252C57.6719 52.5752 56.5119 53.8852 55.2919 55.1052C54.2019 56.1952 53.0919 57.2352 52.0419 58.3752C48.8319 61.8152 45.7419 65.3951 42.9119 69.1951C2.4719 123.775 13.9919 201.085 68.5819 241.535C89.8419 257.275 115.592 265.735 141.872 265.735C144.662 265.735 147.452 265.655 150.232 265.455C155.372 265.115 159.262 260.645 158.912 255.505C158.552 250.355 154.162 246.455 148.952 246.815V246.805Z"
        fill="#FFD500"
      />
      <path
        d="M148.952 246.795C146.092 246.985 143.252 246.995 140.382 246.945C112.952 246.565 87.2119 235.685 67.8419 216.195C48.1419 196.385 37.3619 170.125 37.4319 142.195C37.4519 137.035 33.2819 132.845 28.1119 132.835H28.0919C22.9419 132.835 18.7719 136.995 18.7519 142.155C18.6619 175.055 31.3819 206.035 54.5819 229.375C77.7919 252.695 108.692 265.605 141.592 265.705H141.612C141.702 265.705 141.772 265.655 141.852 265.655V265.725C144.652 265.725 147.442 265.625 150.222 265.435C155.362 265.085 159.252 260.635 158.902 255.485C158.542 250.335 154.152 246.435 148.942 246.805L148.952 246.795Z"
        fill="#FBBA00"
      />
      <path
        d="M74.512 219.275C72.112 219.275 69.722 218.365 67.902 216.535C27.102 175.725 27.102 109.325 67.902 68.5148C75.762 60.6648 84.722 54.1348 94.552 49.1548C99.162 46.8048 104.782 48.6648 107.112 53.2648C109.452 57.8648 107.622 63.4748 103.022 65.8248C94.952 69.9248 87.572 75.2748 81.112 81.7348C47.602 115.265 47.602 169.815 81.112 203.345C84.772 206.985 84.772 212.905 81.112 216.545C79.302 218.375 76.902 219.285 74.522 219.285L74.512 219.275Z"
        fill="#FFEA6B"
      />
    </svg>
  );
};

export default Sprite;
