import React, { useId } from "react";
import * as CheckboxUI from "@radix-ui/react-checkbox";
import { useFormContext } from "react-hook-form";

import Icon from "@/components/atoms/Icon";
import InputContainer from "@/components/forms/controls/InputContainer";
import { cn } from "@/helpers/className";
import useFormError from "@/hooks/useFormError";

interface CheckboxProps
  extends Omit<
    React.ComponentProps<"input">,
    "id" | "aria-describedby" | "value"
  > {
  asArray?: boolean;
  asBoolean?: boolean;
  checked?: boolean;
  children?: React.ReactNode;
  label: string;
  labelClasses?: string;
  labelSuffix?: React.ReactNode;
  name: string;
  onCheckedChange?: (
    name: string,
    value: string | number | string[] | boolean,
  ) => void;
  selectedFilters?: { type: string; value: string }[];
  value?: string | number | string[];
}

const Checkbox = ({
  asArray,
  asBoolean,
  label,
  labelClasses,
  labelSuffix,
  name,
  onCheckedChange,
  className,
  value,
  checked,
  selectedFilters,
  children,
  ...props
}: CheckboxProps) => {
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();
  const error = useFormError(name, errors);
  const id = useId();
  const checkedValue: boolean | string | number | string[] = value ?? label;

  // if(! asArray && ! checked) {
  //   setValue(name, null)
  // }

  const handleOnCheck = (checked: boolean) => {
    let newValue: boolean | string | number | string[];

    if (asArray) {
      let oldValue = getValues(name) as string | number | string[];

      if (!Array.isArray(oldValue)) {
        oldValue = [];
      }

      if (checked) {
        oldValue.push(checkedValue as string);
        // Remove duplicates
        newValue = [...new Set(oldValue)];
      } else {
        newValue = oldValue.filter((value) => value !== checkedValue);
      }
    } else if (asBoolean) {
      newValue = checked;
    } else {
      if (checked) {
        newValue = checkedValue;
      } else {
        newValue = "";
      }
    }

    setValue(name, newValue);
    onCheckedChange?.(name, newValue);
  };

  const checkboxChecked = () => {
    if (selectedFilters === undefined && checked === undefined) {
      return undefined;
    }

    if (checked !== undefined) {
      return checked;
    }

    return selectedFilters?.some(
      (filter) => filter.type === name && filter.value === checkedValue,
    );
  };

  return (
    <InputContainer
      className={cn("relative flex items-start", className)}
      isError={Boolean(error)}
    >
      <div className="flex h-6 items-center">
        <CheckboxUI.Root
          className={cn(
            "grid h-6 w-6 place-items-center rounded-sm border bg-white p-0.5 text-black transition-colors hover:border-green-300 focus:border-green focus:text-green",
            error && "border-negative",
          )}
          id={id}
          value={checkedValue}
          onCheckedChange={handleOnCheck}
          checked={checkboxChecked()}
          data-error={Boolean(error)}
          title={label}
          {...register(name)}
        >
          <CheckboxUI.Indicator>
            <Icon name="check" className="h-4 w-4 text-green" />
          </CheckboxUI.Indicator>
        </CheckboxUI.Root>
      </div>
      <div className="ml-2.5">
        <label
          htmlFor={id}
          className={cn(
            labelClasses,
            "inline-flex flex-wrap items-center gap-1 font-normal",
            props.required && "required",
          )}
        >
          <span dangerouslySetInnerHTML={{ __html: label }}></span>
          {children}
          {labelSuffix && <span className="text-black-300">{labelSuffix}</span>}
        </label>
        {error && <div className="form-error mt-0">{error}</div>}
      </div>
    </InputContainer>
  );
};

export default Checkbox;
