import { useTransition } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import type { FieldPath } from "react-hook-form";
import type { TypeOf, ZodSchema } from "zod";

import { useZodForm } from "@/hooks/useZodForm";
import {
  AllowedJobSearchParams,
  AllowedLanguages,
  SearchHistoryEntry,
} from "@/types/generic";

export const useGetParams = () => {
  const searchParams = useSearchParams();
  return new URLSearchParams(searchParams.toString());
};

// Maps facet names to query params
const facetMap = {
  REGIME: "regime",
  TYPE: "type",
  TAAL: "language",
  SPECIALITY: "speciality",
  SECTOR_GROUP: "sector_group",
};

export const facetNameToParam = (facetName: keyof typeof facetMap) => {
  return facetMap[facetName] || "";
};

export const paramToFacetName = (param: string) => {
  return Object.keys(facetMap).find((key) => facetMap[key] === param);
};

export const useRefreshSearchParams = <Z extends ZodSchema>(
  schema: Z,
  filters: FieldPath<TypeOf<Z>>[],
  defaultValues?: TypeOf<Z>,
) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const params = new URLSearchParams(searchParams.toString());
  const [isPending, startTransition] = useTransition();

  const form = useZodForm({ schema, defaultValues });

  const filtersValues = form.watch(filters);

  const selectedFilters = filters.reduce<
    {
      type: FieldPath<TypeOf<Z>>;
      value: string;
    }[]
  >((acc, filter, index) => {
    const filterValue = filtersValues[index];
    if (Array.isArray(filterValue)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
      const filterFormatted = filterValue.map((value: string) => ({
        value,
        type: filter,
      }));
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      acc.push(...filterFormatted);
    }
    return acc;
  }, []);

  const transition = () => {
    startTransition(() => {
      pathname &&
        router.replace(`${pathname}?${params.toString()}`, { scroll: false });
    });
  };

  const updateFilter = (name: string, value: string[] | string) => {
    params.delete("page");

    if (!value) {
      params.delete(name);
    }
    if (typeof value === "string") {
      params.set(name, value);
    } else {
      value.length > 0
        ? params.set(name, value?.join("|") ?? "")
        : params.delete(name);
    }

    transition();
  };

  const updateQuery = (
    query: string,
    city: string,
    max_distance: string,
    page = 1,
    localStorageKey?: string,
    localStorageLimit = 3,
  ) => {
    params.delete("page");

    query !== "" ? params.set("query", query) : params.delete("query");

    city !== "" ? params.set("city", city) : params.delete("city");

    max_distance
      ? params.set("max_distance", max_distance)
      : params.delete("max_distance");

    if (localStorageKey && (query || city)) {
      handleSearchHistory(localStorageKey, localStorageLimit, params);
    }

    page > 1 ? params.set("page", String(page)) : params.delete("page");

    transition();
  };

  /**
   * Store search params in localStorage
   *
   * @param localStorageKey
   * @param localStorageLimit
   * @param params
   */
  const handleSearchHistory = (
    localStorageKey: string,
    localStorageLimit: number,
    params: URLSearchParams,
  ) => {
    // Store search params in localStorage
    if (typeof window !== "undefined" && params.values()) {
      let searchHistory: SearchHistoryEntry[] = localStorage.getItem(
        localStorageKey,
      )
        ? JSON.parse(localStorage.getItem(localStorageKey) ?? "")
        : [];

      if (!Array.isArray(searchHistory)) {
        searchHistory = [];
      }

      const searchQuery = Object.fromEntries(params.entries());

      // Only store search if there is a query
      if (searchQuery[AllowedJobSearchParams.Query]) {
        if (searchHistory.length > 0) {
          const lastSearch = searchHistory[searchHistory.length - 1];

          // If query in last search is the same as current search, remove last search so it gets replaced with the new params
          if (
            lastSearch[AllowedJobSearchParams.Query] ===
              searchQuery[AllowedJobSearchParams.Query] &&
            lastSearch[AllowedJobSearchParams.City] ===
              searchQuery[AllowedJobSearchParams.City]
          ) {
            searchHistory.pop();
          }
        }

        searchHistory.push(searchQuery);
      }

      // Limit history
      searchHistory = searchHistory.slice(
        Math.max(searchHistory.length - localStorageLimit, 0),
      );

      localStorage.setItem(localStorageKey, JSON.stringify(searchHistory));
    }
  };

  return {
    isPending,
    updateFilter,
    updateQuery,
    selectedFilters,
    form,
    params,
  };
};
